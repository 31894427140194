.Login_contianer01{
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;

}

.Login_telaPrincipal{
    max-width: 300px;
    width: 80%;
    padding: 10px;
    height: 350px;
    border-radius: 10px;
    margin: 0 auto;
    display: block;
    box-sizing: border-box;
    box-shadow: 0 0px 5px rgba(0,0,0,0.3);
}

.Login_form{
    width: 100%;
    margin-top:15px ;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Login_btnEntrar{
    width: 150px;
    border-radius: 10px;
    border:none;
    margin-top: 30px;
    height: 40px;
    background-color:#0075B4;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor:pointer ;
    transition: all 0.12s linear;
}
.Login_btnEntrar:hover{
    background-color:rgba(20,120,220);
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
}


