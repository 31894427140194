
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body{
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

#root{
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
  padding: 5px 5px;
  box-sizing: border-box;
  display: block;
  position: relative;
  
};


