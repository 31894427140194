.incial_Container01{
    display: block;
    width: 100%;
    max-width: 800px;
    box-sizing: border-box;
    margin: 0 auto;
}

.inicialmain_01{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: #0075B4;
    padding-bottom :25px;
    border-bottom-left-radius:50px ;
    border-bottom-right-radius:50px ;
}

.inicialmain_02{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    margin-top: 20px;
    margin-bottom: 10px;
}

.inicial_quadro01_main{
    background-color: white;
    flex: 1;
    width: 50%;
    min-width: 140px;
    max-width: 160px;
    height: 120px;
    border-radius: 8px;
    box-sizing: border-box;
    margin: 5px auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.container_Clientes{
    background-color: white;
    width: 95%;
    margin:10px;
    padding: 8px 8px;
    box-sizing: border-box;
    display: block;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* Dados do veiculo */

.DadosVeiculo{
    border:1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: 7px;
    display: block;
    max-height: 250px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    flex-wrap: wrap;
    position: relative;
}

.ParametrosVeiculos{
    width: 30%;
    flex: 1;
    margin: 5px;
    display: block;
    font-size: 14px;
    text-align: center;
    min-width: 80px;
}
.spnvalor{
    font-size: 12px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
}

.btns{
    position: absolute;
    right: 0;
    width: 30%;
    top: 0;
    max-width: 180px;
    height: 100%;
    border: none;
    background-color: rgba(50,220,120);
    color: white;
    border-radius: 8px;
}
.btnsRed{
    position: absolute;
    right: 0;
    width: 30%;
    top: 0;
    max-width: 180px;
    height: 100%;
    border: none;
    background-color: rgba(220,50,100);
    color: white;
    border-radius: 8px;
}

.btnCopy{
    width: 30%;
    position: absolute;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 6px;
    border-radius: 5px;
    box-shadow: 5px 10px 15px rgba(0, 0, 250, 0.4);
    background-color: #0075B4;
    color: white;
    transition: all 0.12s linear;
    cursor: pointer;
    outline: none;
    max-width: 120px;

}
.btnCopy svg{
    margin-right: 10px;
    fill: white;
}
.btnCopy:hover{
    background-color: #135b81;
}
