.Addcliente_containerBlack{
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    left: 0;
    justify-content: center;
    z-index: 11;
    display: flex;
}

.BlackOpen{
    background-color: rgba(0,0,0,0.8);
    animation: openBlack 0.2s linear;
}
.closekOpen{
    background-color: rgba(0,0,0,0.0);
    animation: closeBlack 0.2s linear;
}
@keyframes openBlack {
    0%{
        background-color:rgba(0,0,0,0.0) 
    }
}

@keyframes closeBlack {
    0%{
        background-color:rgba(0,0,0,0.8) 
    }
}

.addclient_white{
    width: 100%;
    max-width: 800px;
    padding: 10px;
    background-color: white;
    position: absolute;
    display: block;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: auto;
    max-height: 100%;
}

.whiteOpen{
top:0;
opacity: 1;
animation: openWhite 0.2s ease-in-out;
}
@keyframes openWhite {
    0%{
        top:50px;
        opacity: 0; 
    }
    
}

.whiteClose{
    top:40px;
    opacity: 0;
    animation: closeWhite 0.2s ease-in-out;
}

@keyframes closeWhite {
    0%{
        top:0;
        opacity: 1; 
    }
    
}



fieldset{
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    align-items: stretch;
}
.inputStyle{
    width: 25%;
    position: relative;
    max-width: 230px;
    min-width: 110px;
    box-sizing: border-box;
    margin: 5px;
    margin-top:14px ;
}
.inputStyle label{
    position: absolute;
    left:10px;
    top: 3px;
    pointer-events: none;
    font-size: 13px;
}
.inputStyle input, select{
    box-sizing: border-box;
    width: 100%;
    text-indent: 5px;
    border: none;
    border-bottom: 1px solid #212121;
    outline: none;
}

.RegistrarBtn{
    width: 50%;
    max-width: 150px;
    height: 35px;
    border: none;
    outline: none;
    border-radius: 15px;
    background-color: #0075B4;
    color: white;
    transition: all 0.12s linear;
    margin-top: 15px;
}
.RegistrarBtn:hover{
    background-color: #216a92;
}